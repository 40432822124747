<template>
<div class="wrap1">
    <div class="mg-auto" style="width: 1800px;">
        <div class="con_top">
            <h2 class="mb-20"><span>■</span> 상담사 등록</h2>
            <input type="hidden" name="_token" id="_token" value="iu9xcVuFZ79uA3zQNaVTassFPxCio38exMLm1RxR">
            <input type="hidden" name="center_id" id="center_id" value="1">
            <input type="hidden" name="role_id" id="role_id" value="1"><!-- 상담사 -->
            <input type="hidden" name="callback" id="callback" value="">

            <div class="con">
                <div class="con_table4">
                    <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                        <tbody>
                            <tr>
                                <th style="width:111px;">ID <span class="red">*</span></th>
                                <td class="left">
                                    <input type="text" v-model="id" class="ml-20" style="width:200px;" id="id" name="id" value="">
                                    <button type="button" class="default-btn ml-20" @click="checkID()">중복체크</button><br>
                                    <span class="ml-20"> 영문 대/소문자 + 숫자포함 6~20자</span>

                                </td>
                                <th>비밀번호 <span class="red">*</span></th>
                                <td class="left">
                                    <input type="text" v-model="pwd" class="ml-20" style="width:200px;" id="password" name="password" value=""> <br>
                                    <span class="ml-20"> 영문 대/소문자 + 숫자 + 특수문자 포함 6~20자</span>
                                </td>
                            </tr>
                            <tr>
                                <th>성명 <span class="red">*</span></th>
                                <td class="left">
                                    <input type="text" v-model="name" class="ml-20" style="width:200px;" id="name" name="name" value="">
                                </td>
                                <th>성별 <span class="red">*</span></th>
                                <td class="left">
                                    <select v-model="gender" class="ml-20 w-60px">
                                        <option value="남자">남자</option>
                                        <option value="여자">여자</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>휴대폰 번호 <span class="red">*</span></th>
                                <td class="left">
                                    <select class="ml-20" v-model="phone1" id="phone1" name="phone1">
                                        <option value="010">010</option>
                                        <option value="011">011</option>
                                        <option value="016">016</option>
                                        <option value="017">017</option>
                                        <option value="018">018</option>
                                        <option value="019">019</option>
                                    </select> -
                                    <input type="text" v-model="phone2" style="width:100px;" id="phone2" name="phone2" value=""> -
                                    <input type="text" v-model="phone3" style="width:100px;" id="phone3" name="phone3" value="">

                                </td>
                                <th>이메일 <span class="red">*</span></th>
                                <td class="left">
                                    <input type="text" v-model="email1" style="width:120px;" class="ml-20" name="email1" id="email1" value="">@
                                    <input type="text" v-model="email2" style="width:80px;" class="mg5" id="email2" name="email2" value="">
                                    <select v-model="email3" name="email3" id="email3" @change="email2 = email3">
                                        <option value="gmail.com">gmail.com</option>
                                        <option value="naver.com">naver.com</option>
                                        <option value="nate.com">nate.com</option>
                                        <option value="nate.com">daum.net</option>
                                        <option value="" selected="selected">직접입력</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>생년월일 <span class="red">*</span></th>
                                <td class="left">
                                    <select name="birth-yyyy" v-model="birthYY" class="ml-20" id="birth-yyyy">
                                        <option v-for="year in parseYear()" :key="year" :value="year">{{year}}년</option>
                                    </select>
                                    <select name="birth-mm" v-model="birthMM" class="ml-10" id="birth-mm">
                                        <option v-for="index in 12" :key="index" :value="index">{{index}}월</option>
                                    </select>
                                    <select name="birth-dd" v-model="birthDD" class="ml-10" id="birth-dd">
                                        <option v-for="index in 31" :key="index" :value="index">{{index}}일</option>
                                    </select>
                                </td>
                                <th>최종학력 <span class="red">*</span></th>
                                <td class="left">
                                    <select name="birth-dd" v-model="education" class="ml-20 mr-10" id="birth-dd">
                                        <option value="중학교">중졸</option>
                                        <option value="고등학교" selected>고졸</option>
                                        <option value="대학졸업(2,3년)">대졸(2~3년)</option>
                                        <option value="대학졸업(4년)">대졸(4년)</option>
                                        <option value="석사졸업">석사</option>
                                        <option value="박사졸업">박사</option>
                                    </select>
                                    학교/전공 :
                                    <input type="text" v-model="educationInfo" style="width:120px;" class="ml-10" name="email1" id="email1" value="" />
                                </td>
                            </tr>
                            <tr>
                                <th>경력 <span class="red">*</span></th>
                                <td class="left">
                                    <select name="working-day" v-model="career" class="ml-20" id="working-day">
                                        <option v-for="index in 40" :key="index" :value="index">{{index}}년</option>
                                        <option :value="41">40년이상</option>
                                    </select>
                                </td>
                                <th>자격증 <span class="red">*</span></th>
                                <td class="left"><input type="hidden" id="idx_file_cer" name="idx_file_cer" value="0">
                                    <button type="button" class="default-btn ml-20" name="fileUpload" @click="$refs.certFile.click()">파일 선택</button>
                                    <input v-show="false" ref="certFile" type="file" @change="uploadCert($event)" /><br>
                                    <span v-for="(item, index) of certFiles" :key="index" class="ml-20" style="cursor:pointer" @click="detailFile()">{{item.name}}<br></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00" style="border-top: none;">
                        <tbody>
                            <tr>
                                <th style="width: 78px;">한줄소개 <span class="red">*</span></th>
                                <td class="left">
                                    <input type="text" v-model="introduce" class="ml-20 w-98per" id="introduce" name="introduce" value="">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00" style="border-top: none;">
                        <tbody>
                            <tr>
                                <th style="width: 78px;">사진 <span class="red">*</span></th>
                                <td class="left">
                                    <button type="button" class="default-btn ml-20" name="fileUpload" @click="$refs.profileFile.click()">파일 선택</button>
                                    <input v-show="false" ref="profileFile" type="file" @change="uploadProfile($event)" /><br>
                                    <span v-if="profileFile.name" class="ml-20" style="cursor:pointer" @click="detailFile()">{{profileFile.name}}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00" style="border-top: none;">
                        <tbody>
                            <tr>
                                <th style="width: 78px;">재직현황 <span class="red">*</span></th>
                                <td class="left" style="width: 485px;">
                                    <select v-model="duty" class="ml-20 w-100px">
                                        <option value="재직">재직</option>
                                        <option value="휴직">휴직</option>
                                        <option value="퇴직">퇴직</option>
                                    </select>
                                </td>
                                <th style="width: 85px">센터 <span class="red">*</span></th>
                                <td class="left">
                                    {{centerName}}
                                </td>
                            </tr>
                            <tr>
                                <th style="width: 78px;">전문분야 <span class="red">*</span></th>
                                <td class="left" style="width: 470px;">
                                    <select v-model="idxCrmMetaPro" class="ml-20 w-100px">
                                        <option value="-1">전문분야</option>
                                        <option v-for="(item, index) of proList" :key="index" :value="item.idx">{{item.type}}</option>
                                    </select>
                                </td>
                                <!-- <th style="width: 81px">회기당요율 </th>
                                <td class="left">
                                    <input type="text" v-model="pay" class="ml-20" style="width:200px;" value=""> % <br>
                                </td> -->
                            </tr>
                            <tr>
                                <th>근무요일 <span class="red">*</span></th>
                                <td class="left" colspan="3">
                                    <table class="default_table w-1000px mt-20 ml-30 mb-20">
                                        <tbody>
                                            <tr>
                                                <th class="reset w-30px"></th>
                                                <th class="w-40px">09:00</th>
                                                <th class="w-40px">09:30</th>
                                                <th class="w-40px">10:00</th>
                                                <th class="w-40px">10:30</th>
                                                <th class="w-40px">11:00</th>
                                                <th class="w-40px">11:30</th>
                                                <th class="w-40px">12:00</th>
                                                <th class="w-40px">12:30</th>
                                                <th class="w-40px">13:00</th>
                                                <th class="w-40px">13:30</th>
                                                <th class="w-40px">14:00</th>
                                                <th class="w-40px">14:30</th>
                                                <th class="w-40px">15:00</th>
                                                <th class="w-40px">15:30</th>
                                                <th class="w-40px">16:00</th>
                                                <th class="w-40px">16:30</th>
                                                <th class="w-40px">17:00</th>
                                                <th class="w-40px">17:30</th>
                                                <th class="w-40px">18:00</th>
                                                <th class="w-40px">18:30</th>
                                                <th class="w-40px">19:00</th>
                                                <th class="w-40px">19:30</th>
                                                <th class="w-40px">20:00</th>
                                                <th class="w-40px">20:30</th>
                                                <th class="w-40px">21:00</th>
                                                <th class="w-40px">21:30</th>
                                            </tr>
                                            <tr v-for="(item, idx) of workTimeList" :key="idx">
                                                <td>
                                                    {{item.day}}
                                                </td>
                                                <td v-for="(subItem, index) of item.table" :key="index"><input type="checkbox" v-model="subItem.use" /> </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="btns3 mgB80">
                        <a class="btn_sms mr-10 pointer" @click="cancel()">취소</a>
                        <a class="btn_cancle pointer" @click="postUserInfo()">등록</a>
                        <!-- <a class="btn_cancle pointer" @click="test()">test</a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    IDFormatCheck,
    PWFormatCheck,
    EmptyCheck
} from '@/utils/validation.js'
import {
    GetUrlParams
} from '@/utils/common.js'
export default {
    data: () => ({
        id: '', // 아이디
        pwd: '', // 비밀번호,
        name: '', // 이름
        gender: '남자', // 성별
        phone1: '010', // 휴대폰 앞자리
        phone2: '', // 휴대폰 가운데자리
        phone3: '', // 휴대폰 마지막자리
        email1: '', // 이메일 앞자리
        email2: '', // 이메일 뒷자리
        email3: '', // 이메일 도메인 선택시
        birthYY: 2020, // 생년월일 YYYY
        birthMM: 1, // 생년월일 MM
        birthDD: 1, // 생년월일 DD
        birthTempMM: 1, // 생년월일 MM
        birthTempDD: 1, // 생년월일 DD
        education: '중학교', // 최종학력
        educationInfo: '', // 학교/전공
        career: 1, // 경력
        introduce: '', // 한줄소개
        duty: '재직', // 재직현황
        idxCrmCenter: -1, // center idx
        pay: 0, // 회기당 급여

        profileFiles: [],

        workTimeList: [], // 근무요일 타임테이블

        certFiles: [], // 자격증 파일 리스트 (최대 10개)
        profileFile: '', // 프로필 사진 파일
        autoSearchCenter: '', // 센터 자동완성 검색
        isAutoCenter: false, // 센터 자동완성 on/off
        center: '', // 센터명
        centerList: [], // 센터명 리스트 (자동완성)

        idxCrmMetaPro: -1,
        proList: [],
        centerName: '',
        permission: 'N'
    }),

    mounted() {
        this.setWorkTimeList()
        var oParams = GetUrlParams()
        this.idxCrmCenter = oParams.idxCrmCenter
        this.getCenterListAll()
        this.getMetaList()
    },

    methods: {

        checkID() {

            // 아이디 검사
            if (!EmptyCheck(this.id, '아이디를')) {
                return false
            } else {
                if (!IDFormatCheck(this.id)) {
                    return false
                }
            }

            var params = {
                id: this.id
            }
            this.axios.get('/api/v1/user/dup', {
                    params: params
                })
                .then(res => {
                    if (res.data.err === 0) {
                        alert('사용 가능한 아이디입니다.')
                        this.isValidID = true
                    } else {
                        alert('이미 사용중인 아이디입니다.')
                    }
                })
                .catch(err => {
                    console.log(err)
                    alert(err)
                })
        },

        // 유입경로, 상담구분, 상담유형 리스트
        getMetaList() {
            this.axios.get('/api/v1/client/meta', {})
                .then(res => {
                    if (res.data.err === 0) {
                        this.proList = res.data.proList
                    }
                })
        },

        getCenterListAll() {
            this.axios.get('/api/v1/center/all', {})
                .then(res => {
                    if (res.data.err === 0) {
                        this.centerList = res.data.centerList

                        if(this.idxCrmCenter === 0) {
                            this.centerName = '본원센터'
                        }  else {
                            for (var center of res.data.centerList) {
                                if (center.idx === this.idxCrmCenter * 1) {
                                    this.centerName = center.name
                                }
                            }
                        }

                    } else {
                        this.centerList = []
                        //alert('센터리스트 결과가 없습니다.')
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },

        // 현재 년도에 따라서 120년만큼 selectbox
        parseYear() {
            var nowYear = new Date().getFullYear()
            var parseYear = []
            for (var i = 0; i <= 120; i++) {
                parseYear.push(nowYear - i)
            }
            return parseYear
        },

        // 자격증 파일 업로드 (최대 5개까지)
        uploadCert(event) {

            // 포맷 검사
            var allowFormat = ['pdf', 'jpg', 'jpeg', 'png', 'gif', 'PDF', 'JPG', 'JPEG', 'PNG', 'GIF']
            var checked = false
            for (var item of allowFormat) {
                if (event.target.files[0].name.includes(item)) {
                    checked = true
                    break
                } else {
                    continue
                }
            }

            if (checked) {
                // 용량 검사
                if (event.target.files[0].size > 1048576 * 2) {
                    alert('2MB 이하만 업로드 가능합니다.')
                    this.$refs.certFile.value = ''
                    return false
                }
                if (this.certFiles.length >= 5) {
                    alert('자격증은 최대 5개까지 업로드 가능합니다.')
                    return false
                } else {
                    this.certFiles.push(event.target.files[0])
                }
            } else {
                alert('파일 포맷은 pdf, jpg, jpeg, png, gif형식만 가능합니다.')
                this.$refs.certFile.value = ''
                return false
            }

        },

        // 프로필 사진 업로드
        uploadProfile(event) {
            // 포맷 검사
            var allowFormat = ['jpg', 'jpeg', 'png', 'gif', 'JPG', 'JPEG', 'PNG', 'GIF']
            var checked = false
            for (var item of allowFormat) {
                if (event.target.files[0].name.includes(item)) {
                    checked = true
                    break
                } else {
                    continue
                }
            }

            if (checked) {
                // 용량 검사
                if (event.target.files[0].size > 1048576 * 2) {
                    alert('2MB 이하만 업로드 가능합니다.')
                    this.$refs.profileFile.value = ''
                    return false
                }

                this.profileFile = event.target.files[0]
                this.$refs.profileFile.value = ''
            } else {
                alert('파일 포맷은 jpg, jpeg, png, gif형식만 가능합니다.')
                this.$refs.profileFile.value = ''
                return false
            }
        },

        fileUpload(idxCrmStaff) {
            var params = new FormData()

            // var params = new URLSearchParams()
            params.append('idxCertFile1', -1)
            params.append('idxCertFile2', -1)
            params.append('idxCertFile3', -1)
            params.append('idxCertFile4', -1)
            params.append('idxCertFile5', -1)
            params.append('idxCrmStaff', idxCrmStaff)
            params.append('type', '')
            params.append('type1', '')
            params.append('type2', '')
            params.append('type3', '')
            params.append('type4', '')
            params.append('type5', '')

            // 기본 타입으로 추가해놓기
            params.append('certiFile1', new Blob())
            params.append('certiFile2', new Blob())
            params.append('certiFile3', new Blob())
            params.append('certiFile4', new Blob())
            params.append('certiFile5', new Blob())
            params.append('profileFile', new Blob())

            for (var [index, file] of this.certFiles.entries()) {
                params.delete('certiFile' + (index + 1))
                params.append('certiFile' + (index + 1), file)
                params.delete('type' + (index + 1))
                params.append('type' + (index + 1), 'I')
            }

            if (this.profileFile !== '') {
                params.delete('profileFile')
                params.append('profileFile', this.profileFile)
                params.delete('type')
                params.append('type', 'I')
            }

            this.axios.post('/api/v1/user/files', params, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    alert('등록되었습니다.')
                    this.$router.push('/convention/counselor_manage')
                })
                .catch(err => {
                    console.log(err)
                })
        },

        // 등록요청
        postUserInfo() {
            // 아이디 검사
            if (!EmptyCheck(this.id, '아이디를')) {
                return false
            } else {
                if (!IDFormatCheck(this.id)) {
                    return false
                }
            }

            // 아이디 중복체크 했는지 여부
            if (!this.isValidID) {
                alert('아이디 중복체크를 해주세요.')
                return false
            }

            // 비밀번호 검사
            if (!EmptyCheck(this.pwd, '암호를')) {
                return false
            } else {
                if (!PWFormatCheck(this.pwd)) {
                    return false
                }
            }

            // 이름 검사
            if (!EmptyCheck(this.name, '성명을')) {
                return false
            }

            // 핸드폰 번호를 전부 입력한 경우에만 핸드폰 포맷으로 파싱
            if (this.phone2 !== '' && this.phone3 !== '') {
                var phone = this.phone1 + '-' + this.phone2 + '-' + this.phone3
            } else {
                var phone = ''
            }

            if (phone === '') {
                alert('핸드폰정보를 입력해주세요')
                return false
            }

            // 이메일을 전부 입력한경우에만 이메일 포맷으로 파싱
            if (this.email1 !== '' && this.email2 !== '') {
                var email = this.email1 + '@' + this.email2
            } else {
                var email = ''
            }

            if (email === '') {
                alert('이메일을 입력해주세요')
                return false
            }

            // 생년월일 데이터 파싱
            if(this.birthMM < 10) {
                this.birthTempMM = "0" + this.birthMM
            }
            if(this.birthDD < 10) {
                this.birthTempDD = "0" + this.birthDD
            }
            var birth = this.birthYY.toString() + '-' + this.birthTempMM.toString() + '-' + this.birthTempDD.toString()

            if (birth === '') {
                alert('생년월일을 입력해주세요')
                return false
            }

            if (!EmptyCheck(this.educationInfo, '학교/전공을')) {
                return false
            }

            if (this.certFiles.length === 0)
            {
                alert('자격증 파일을 첨부하세요')
                return false
            }

            if (!EmptyCheck(this.introduce, '한줄소개를')) {
                return false
            }

            if (this.profileFile.length === 0) {
                alert('사진 파일을 첨부하세요')
                return false
            }

            if (this.idxCrmCenter === -1) {
                alert('센터를 선택해주세요')
                return false
            }

            if(this.idxCrmMetaPro === -1) {
                alert('전문분야를 선택해주세요')
                return false
            }

            // 자격증 파일 파라미터형태로 변경
            var certificateList = []
            for (var cert of this.certFiles) {
                certificateList.push({
                    certificate: cert
                })
            }

            // 근무요일 테이블 파라미터형태로 변경
            var workTimeList = []
            var copyWorkTimeList = JSON.parse(JSON.stringify(this.workTimeList))
            for (var item of copyWorkTimeList) {
                for (var subItem of item.table) {
                    if (subItem.use) {
                        delete subItem['use']
                        workTimeList.push(subItem)
                    }
                }
            }

            console.log('workTimeList', workTimeList)

            if(workTimeList.length === 0) {
                alert('근무시간을 선택해주세요')
                return false
            }

            var vo = {
                authority: 'STAFF',
                birth: birth,
                career: this.career,
                duty: this.duty,
                education: this.education,
                educationInfo: this.educationInfo,
                email: email,
                id: this.id,
                idxCrmCenter: this.idxCrmCenter,
                introduce: this.introduce,
                memo: '',
                name: this.name,
                pay: this.pay,
                permission: this.permission,
                phone: phone,
                proField: this.proField,
                pwd: this.pwd,
                gender: this.gender,
                workTimeList: workTimeList,
                idxCrmMetaPro: this.idxCrmMetaPro
            }

            this.axios.post('/api/v1/user', vo)
                .then(res => {
                    if (res.data.err === 0) {
                        this.fileUpload(res.data.userVO.idx)
                    } else {
                        alert(res.data.result)
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },

        // 근무요일 기본 테이블구조 변수에 할당
        setWorkTimeList() {
            this.workTimeList = []
            var days = ['월', '화', '수', '목', '금', '토', '일']
            for (var day of days) {
                var tempDayTable = {
                    day: day,
                    table: []
                }
                let time = 9
                for (var index = 0; index < 26; index++) {
                    //time += index
                    var stringTime = ''
                    /* if (time >= 10) {
                        stringTime = time.toString() + ':' + '00'
                    } else {
                        stringTime = '0' + time.toString() + ':' + '00'
                    } */
                    if (time >= 10) {
                        if (index % 2 == 0) {
                            stringTime = time.toString() + ':' + '00'
                        } else {
                            stringTime = time.toString() + ':' + '30'
                            time += 1
                        }
                    } else {
                        if (index % 2 == 0) {
                            stringTime = '0' + time.toString() + ':' + '00'
                        } else {
                            stringTime = '0' + time.toString() + ':' + '30'
                            time += 1
                        }
                    }
                    tempDayTable.table.push({
                        use: false,
                        day: day,
                        time: stringTime
                    })
                }
                this.workTimeList.push(tempDayTable)
            }
        },

        // 이전 페이지로 이동
        cancel() {
            history.back()
        }
    }
}
</script>
